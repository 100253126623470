import type {VersionPageTabKey} from '../version/page/tabs/VersionPageTabKey'

export const paths = {
  home: () => '/',

  admin: () => '/admin',
  userAdmin: () => '/admin/user',
  userAdminCreate: () => '/admin/user/create',
  userAdminEdit: (id: string) => `/admin/user/edit/${id}`,
  userAdminEditPattern: `/admin/user/edit/:id` as const,

  login: '/login' as const,

  disputes: () => '/',
  dispute: (id: string) => `/dispute/${id}`,
  disputePattern: `/dispute/:disputeId` as const,
  disputeRootPattern: `/dispute/:disputeId` as const,

  disputeSettings: (disputeId: string) => `/dispute/${disputeId}/settings`,
  disputeSettingsPattern: '/dispute/:id/settings' as const,

  newDisputePattern: '/newdispute' as const,

  disputeSharingList: (disputeId: string) => `/dispute/${disputeId}/access`,
  disputeSharingListPattern: `/dispute/:id/access` as const,

  disputeEditOrViewEventPattern: '/dispute/:disputeId/events/:eventId/:mode' as const,
  disputeEditOrViewEvent: (disputeId: string, eventId: string, mode: 'edit' | 'view') =>
    `/dispute/${disputeId}/events/${eventId}/${mode}`,
  disputeNewEventPattern: '/dispute/:id/events/new' as const,
  disputeNewEvent: (disputeId: string) => `/dispute/${disputeId}/events/new`,
  disputeEventsPattern: '/dispute/:id/events' as const,
  disputeEvents: (disputeId: string) => `/dispute/${disputeId}/events`,

  disputeMetricsPattern: '/dispute/:disputeId/history' as const,
  disputeMetrics: (disputeId: string) => `/dispute/${disputeId}/history`,

  versionCompare: (disputeId: string) => `/dispute/${disputeId}/compare`,
  versionComparePattern: `/dispute/:disputeId/compare` as const,

  version: (disputeId: string, versionId: string, tabKey: VersionPageTabKey = 'casebot') =>
    `/dispute/${disputeId}/version/${versionId}/${tabKey}`,
  versionPattern: '/dispute/:disputeId/version/:versionId/:activeTab?/:subTab?' as const,
}

export type VersionPagePaths = {
  activeTab?: string
  disputeId: string
  subTab?: string
  versionId: string
}
