import {Chat, DeleteIcon, TertiaryButton, message, theme, useToggle} from '@settleindex/react2'
import type {WsRoutes} from '@settleindex/ws/src'
import type {ChatMessageType} from '@settleindex/ws/src/help/ChatSchema.js'
import {hc} from 'hono/client'
import {useCallback, useMemo} from 'react'
import useLocalStorage from 'use-local-storage'
import {captureException} from '#lib/captureException.js'
import {secondaryNavHeight} from '#lib/navigation/secondary/secondaryNavHeight.js'
import {useConfig} from '#lib/useConfig/useConfig.js'
import {useUser} from '#lib/user/useUser/useUser.js'

interface HelpChatProps {}

const chatErrorMessage = `Couldn't send your chat message`

export const HelpChat: React.FC<HelpChatProps> = () => {
  const isLoading = useToggle(false)
  const [collapsed, setCollapsed] = useLocalStorage<boolean>('helpChatCollapsed', true)
  const pinnedMessage: ChatMessageType = useMemo(
    () => ({
      date: '1111-11-11T11:11:11Z',
      kind: 'pinned',
      message: `Hello! I'm Dan, and my job is to help you understand everything about SettleIndex. I'm here for training and familiarization purposes.
    For live cases, it's important to rely on human support for checking models and advice.

    Please note: this is an AI assistant, responses are not always accurate. Your personal or case data is never sent automatically. Any documents uploaded or data input about a case are sent to our LLM instance.

    There is no risk in uploading any documents that are publicly available, such as Court Pleadings.
    If you want to keep your own assessment of your case within the SettleIndex domain, you should enter your own adjustments directly in SettleIndex’.`,
    }),
    [],
  )
  const [messages, setMessages] = useLocalStorage<ChatMessageType[]>('helpChatMessages_2000', [])
  const {wsUrl} = useConfig()
  const {getToken} = useUser()

  const sendChat = useCallback(
    (newMessages: any) => {
      isLoading.setTrue()
      // @ts-ignore
      const client = hc<WsRoutes>(wsUrl)

      const handleError = (e: unknown) => {
        message.error(chatErrorMessage)
        console.error('error in help/chat:', e)
        captureException(new Error(`Error in HelpChat:e${JSON.stringify(e)}`))
        setMessages([...newMessages, {message: chatErrorMessage, kind: 'error', date: new Date().toISOString()}])
      }

      // @ts-ignore hono types are not very good unfortunately
      return client.help.chat
        .$post(
          {
            json: {
              messages: newMessages,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          },
        )
        .then(async (res: Response) => {
          if (res.ok) {
            const data = await res.json()
            if (Array.isArray(data.messages)) {
              setMessages(data.messages)
            } else {
              handleError(data)
            }
          } else {
            handleError(res)
          }
        })
        .catch((e) => {
          handleError(e)
        })
        .finally(isLoading.setFalse)
    },
    [wsUrl, getToken, isLoading, setMessages],
  )

  const onSubmit = useCallback(
    (messages: any) => {
      setMessages(messages)
      sendChat(messages.filter((m) => m.kind !== 'pinned' && m.kind !== 'error'))
    },
    [setMessages, sendChat],
  )

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        position: 'fixed',
        bottom: 0,
        right: 20,
        zIndex: 1000,
        height: collapsed ? 45 : `calc(100vh - ${secondaryNavHeight}px)`,
      }}
    >
      <Chat
        placeholder={'Ask Dan a question and press enter'}
        onCollapseChange={setCollapsed}
        collapsed={collapsed}
        loading={isLoading.value}
        messages={messages}
        pinnedMessage={pinnedMessage}
        onSubmit={onSubmit}
        footer={
          messages?.length > 1 && (
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <TertiaryButton
                size="small"
                icon={<DeleteIcon size={16} />}
                onClick={() => setMessages([])}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  color: theme.colors.primary.textOnNeutral,
                }}
              >
                Clear chat
              </TertiaryButton>
            </div>
          )
        }
      />
    </div>
  )
}
