import {LoadingIndicatorFullPage} from '@settleindex/react2'
import * as React from 'react'

interface LoadingUserSpinnerProps {}

export const LoadingUserSpinner: React.FC<LoadingUserSpinnerProps> = () => {
  React.useEffect(() => {
    // Reload the page after 3 seconds if the user is not loaded.
    // This is to handle the case where the OIDC library fails to load the user
    // due to getting 401 from the /userinfo endpoint, but then fails to report
    // that problem upstream (see SX-1431).
    const timeoutId = setTimeout(() => {
      window.location.reload()
    }, 3000)

    return () => clearTimeout(timeoutId)
  }, [])

  return <LoadingIndicatorFullPage />
}
