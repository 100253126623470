import * as React from 'react'

import {Breadcrumb, GridCol, GridRow, LoadingIndicator} from '@settleindex/react2'
import * as testIds from '@settleindex/testids'
import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {contentHorizontalPadding} from '../../page/consts'
import {SecondaryNavLayout} from './SecondaryNavLayout'

interface Props {
  breadcrumbs?: React.ReactElement[]
  loading?: boolean
  tools?: React.ReactElement[]
}

export const SecondaryNav: React.FC<Props> = ({breadcrumbs = [], loading, tools = []}) => {
  const {loading: appIsLoading} = useAppIsLoading()
  const isLoading = React.useMemo(() => appIsLoading || loading, [appIsLoading, loading])
  const finalTools = React.useMemo(
    () => (isLoading ? [<LoadingIndicator key="i" />, ...tools] : tools),
    [isLoading, tools],
  )

  return (
    <SecondaryNavLayout data-test-id={testIds.secondaryNav.nav}>
      <GridRow align="middle">
        <GridCol span={16}>
          <Breadcrumb>
            {breadcrumbs.map((b, i) => (
              <span key={i}>{b}</span>
            ))}
          </Breadcrumb>
        </GridCol>
        <GridCol
          span={8}
          style={{
            textAlign: 'right',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: contentHorizontalPadding,
          }}
        >
          {finalTools.map((t, i) => (
            <span key={i} style={{marginLeft: 10}}>
              {t}
            </span>
          ))}
        </GridCol>
      </GridRow>
    </SecondaryNavLayout>
  )
}
