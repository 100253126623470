import React, {Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router'

import {LoadingIndicatorFullPage} from '@settleindex/react2'

import {paths} from './paths'

import {useUser} from '#lib/user/useUser/useUser.js'

const LoginPage = React.lazy(() => import('../user/LoginPage'))

export const PublicRouting: React.FC = () => {
  const {isLoading} = useUser()

  if (isLoading) {
    return <LoadingIndicatorFullPage />
  }

  return (
    <Suspense fallback={<LoadingIndicatorFullPage />}>
      <Routes>
        <Route path={paths.login} element={<LoginPage />} />
        <Route element={<Navigate to={paths.login} />} path={'/*'} />
      </Routes>
    </Suspense>
  )
}
