import {atom, useAtom} from 'jotai'
import {useCallback} from 'react'

const debugLabelAtom = atom<boolean>(false)

export const useDebugLabels = () => {
  const [debugLabelsEnabled, setDebugLabelsEnabled] = useAtom(debugLabelAtom)

  const toggleDebugLabels = useCallback(() => {
    setDebugLabelsEnabled((val) => !val)
  }, [setDebugLabelsEnabled])

  return {
    toggleDebugLabels,
    debugLabelsEnabled,
    setDebugLabelsEnabled,
  }
}
