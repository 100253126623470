import React, {Suspense} from 'react'
import {Route} from 'react-router'

import type {Right} from '@settleindex/domain'
import {Navigate, Routes} from 'react-router'
import {DebugSection} from '../debug/DebugSection'
import {LoadingIndicatorWithNav} from '../page'
import {usePageTitle} from '../pageTitle/usePageTitle'
import {useUser} from '../user/useUser/useUser'
import {paths} from './paths'

const DisputeDashboardContainer = React.lazy(() => import('../dispute/dashboard/DisputeDashboardContainer'))
const NewDisputeFormContainer = React.lazy(() => import('../dispute/form/NewDisputeFormContainer.js'))
const EditDisputeFormContainer = React.lazy(() => import('../dispute/form/EditDisputeFormContainer.js'))
const DisputeListContainer = React.lazy(() => import('../dispute/list/DisputeListContainer'))
const DisputeHistoryContainer = React.lazy(
  () => import('../dispute/history/disputeHistoryPage/DisputeHistoryContainer'),
)
const TeamContainer = React.lazy(() => import('../disputeTeam/teamPage/TeamContainer'))
const UserCreatePageContainer = React.lazy(() => import('../admin/user/create/UserCreatePageContainer'))
const UserEditPageContainer = React.lazy(() => import('../admin/user/edit/UserEditPageContainer'))
const UserListPageContainer = React.lazy(() => import('../admin/user/list/UserListPageContainer'))
const VersionCompareContainer = React.lazy(() => import('../dispute/versionCompare/VersionCompareContainer'))
const VersionContainer = React.lazy(() => import('../version/VersionContainer'))

interface LoggedInRoutingProps {
  rights?: Right[]
}

export const LoggedInRouting: React.FC<LoggedInRoutingProps> = ({rights}) => {
  const {isAdmin} = useUser()
  usePageTitle()
  return (
    <Suspense fallback={<LoadingIndicatorWithNav />}>
      <Routes>
        <Route path="/" element={<DisputeListContainer />} />
        <Route path={paths.newDisputePattern} element={<NewDisputeFormContainer />} />
        <Route path={paths.disputeSettingsPattern} element={<EditDisputeFormContainer />} />
        <Route path={paths.disputeSharingListPattern} element={<TeamContainer />} />
        <Route path={paths.disputePattern} element={<DisputeDashboardContainer />} />
        <Route path={paths.versionComparePattern} element={<VersionCompareContainer />} />
        <Route path={paths.disputeMetricsPattern} element={<DisputeHistoryContainer />} />
        <Route path={paths.versionPattern} element={<VersionContainer />} />
        <Route path={paths.admin()} element={<Navigate to={paths.userAdmin()} />} />
        <Route path={paths.userAdmin()} element={<UserListPageContainer rights={rights} />} />
        <Route path={paths.userAdminCreate()} element={<UserCreatePageContainer rights={rights} />} />
        <Route path={paths.userAdminEditPattern} element={<UserEditPageContainer rights={rights} />} />
        <Route path="*" element={<Navigate to={paths.home()} />} />
      </Routes>
      {isAdmin && <DebugSection />}
    </Suspense>
  )
}
