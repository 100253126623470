import {OidcUserStatus, useOidc, useOidcIdToken, useOidcUser} from '@axa-fr/react-oidc'
import {useAtom} from 'jotai'
import * as React from 'react'

import {Right, getGroupsRights} from '@settleindex/domain'

import Debug from 'debug'
import {useConfig} from '../../useConfig/useConfig'
import type {User} from './User'
import {userAtom} from './userAtom'

const debug = Debug('app:useUser')

interface UseUser {
  getToken: () => string | undefined
  getUserId: () => Promise<string | undefined>
  isAdmin: boolean
  isAuthenticated: boolean
  isLoading: boolean
  login: (callbackPath?: string) => Promise<unknown>
  logout: () => Promise<void>
  user: User | null
}

export interface Authenticated extends UseUser {
  user: User
}

interface Unauthenticated extends UseUser {
  user: null
}

interface OidcUser {
  'cognito:groups'?: string[]
  email: string
  groups: string[]
  id: string
  name: string
  sub: string
}

/**
 * Note that the configuration of the OIDC client is done via the `<OidcProvider config={...}>` in `index.tsx`.
 */
export const useUser = (): Authenticated | Unauthenticated => {
  const [user, setUser] = useAtom(userAtom)
  const {isAuthenticated, login, logout: oidcLogout} = useOidc()
  const {idToken, idTokenPayload} = useOidcIdToken()
  const {oidcUser, oidcUserLoadingState} = useOidcUser<OidcUser>()
  const config = useConfig()

  const logout = React.useCallback(async () => {
    // This is a Cognito specific way to log out, as per
    // https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
    await oidcLogout('/', {
      client_id: config.oidcClientId,
      logout_uri: `${location.origin}/`,
    })
  }, [config.oidcClientId, oidcLogout])

  /**
   * Run once when the user changes
   */
  React.useEffect(() => {
    debug(`oidcUserLoadingState %s`, oidcUserLoadingState)
    if (oidcUserLoadingState === OidcUserStatus.LoadingError) {
      logout()
        .then(() => {
          debug(`logout done`)
          document.location.reload()
        })
        .catch((err) => {
          debug(`logout error %s`, err)
          document.location.reload()
        })
      return
    }

    if (!oidcUser || oidcUserLoadingState !== OidcUserStatus.Loaded) {
      return
    }

    // Once the user is set, we never change it again. This is to avoid some
    // subtle bugs in the App routing, that depends on the existence of the
    // user (show one of two routes depending on the user being logged in).
    // If we keep changing the user object, the router is recreated and the
    // Apollo client gets very confused, amongst other things.
    if (user !== null) {
      return
    }

    setUser({
      email: oidcUser.email,
      id: oidcUser.sub,
      name: oidcUser.email,
      // These rights are related to system administration and have nothing to
      // do with access to specific cases or models. See
      // `documentation/Authorization/Authorization.md`
      // We only ever care about AWS Cognito groups as it only makes sense on
      // the official instance. Customer instances do not need user admins.
      // See also the `userAdminEnabled` feature toggle in `useConfig`
      // If the user admin feature is disabled, we return an empty array so the
      // user is considered non-admin, no matter Cognito says.
      rights: config.userAdminEnabled ? getGroupsRights(idTokenPayload['cognito:groups'] ?? []) : [],
    })
  }, [logout, config.userAdminEnabled, idTokenPayload, oidcUser, oidcUserLoadingState, setUser, user])

  const getToken = React.useCallback(() => idToken, [idToken])

  const getUserId = React.useCallback(() => Promise.resolve(user?.id), [user?.id])

  const isLoading = React.useMemo(() => oidcUserLoadingState === OidcUserStatus.Loading, [oidcUserLoadingState])

  const isAdmin = React.useMemo(() => Boolean(user?.rights?.includes(Right.AdminUsers)), [user?.rights])

  return {
    getToken,
    getUserId,
    isAdmin,
    isAuthenticated,
    isLoading,
    login,
    logout,
    user,
  }
}
