import '@settleindex/react2/style.css'

import {ApolloProvider} from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import {FeaturevisorProvider} from '@featurevisor/react'
import {type FeaturevisorInstance, createInstance} from '@featurevisor/sdk'
import * as React from 'react'
import {BrowserRouter} from 'react-router'

import {T, cond} from '@settleindex/fp'
import {MessagePortal} from '@settleindex/react2'

import {useAutoLogout} from '#lib/autologout/useAutoLogout.js'
import {HelpChat} from '#lib/help/chat/HelpChat.js'
import {LoadingUserSpinner} from '#lib/user/LoadingUserSpinner.js'
import {getApolloClient} from './apollo/getApolloClient'
import {AppProvider} from './appContext/AppProvider'
import {emptyFeaturevisorDatafile} from './featureFlags/emptyFeaturevisorDatafile'
import {HelpProvider} from './help/HelpProvider'
import {Page} from './page/'
import {LoggedInRouting, PublicRouting} from './routing/'
import {EnforceActionContainer} from './user/EnforceActionContainer'
import {useUser} from './user/useUser/useUser'

interface AppProps {
  featurevisorUrl?: string
  graphUrl: string
  useBugsnag?: boolean
}

let featurevisorInstance: FeaturevisorInstance

export const App: React.FC<AppProps> = ({featurevisorUrl, graphUrl, useBugsnag}) => {
  // Create the Featurevisor instance once
  React.useEffect(() => {
    if (featurevisorInstance) {
      return
    }

    const opts = featurevisorUrl ? {datafileUrl: featurevisorUrl} : {datafile: emptyFeaturevisorDatafile}
    featurevisorInstance = createInstance(opts)
  }, [featurevisorUrl])

  const {getToken, getUserId, isAdmin, isLoading: userIsLoading, logout, user, isAuthenticated} = useUser()

  const autoLogout = useAutoLogout({onTimeout: logout})
  React.useEffect(() => {
    if (user) {
      return autoLogout.start()
    } else {
      autoLogout.clear()
    }
  }, [autoLogout, user])

  React.useEffect(() => {
    useBugsnag && Bugsnag.setUser(user?.id, user?.email)
  }, [useBugsnag, user?.email, user?.id])

  const apolloClient = getApolloClient({graphUrl, getToken, getUserId})

  const content = React.useMemo(() => {
    // @axa-fr/react-oidc on initial load will expose the token, set isAuthenticated to true but the user will be null.
    // So we just need for it to load it. In this case not having a `user` is fine, we should not show the login page.
    const token = getToken()
    const isLoadingAuthenticatedUser = !!token && isAuthenticated && !user

    return cond([
      //
      [() => isLoadingAuthenticatedUser || userIsLoading, () => <LoadingUserSpinner />],
      [
        () => !!user,
        () => (
          <>
            <Page isAdmin={isAdmin} logout={logout} username={user?.name}>
              <HelpProvider />
              <LoggedInRouting rights={user?.rights} />
            </Page>
            <EnforceActionContainer />
          </>
        ),
      ],
      [T, () => <PublicRouting />],
    ])()
  }, [isAuthenticated, getToken, isAdmin, logout, user, userIsLoading])

  return (
    <FeaturevisorProvider instance={featurevisorInstance}>
      <ApolloProvider client={apolloClient}>
        <AppProvider>
          <BrowserRouter>{content}</BrowserRouter>
          <MessagePortal />
          {isAuthenticated && <HelpChat />}
        </AppProvider>
      </ApolloProvider>
    </FeaturevisorProvider>
  )
}
