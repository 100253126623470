import {atom, useAtom} from 'jotai'

const debugPanelVisibleAtom = atom<boolean>(false)
const debugPanelContentAtom = atom<any>(null)
const debugPanelCollapseAtom = atom<number | boolean>(false)

/**
 * See JSONView:
 * When set to true, all nodes will be collapsed by default. Use an integer value to collapse at a particular depth.
 */

export const useDebugPanel = () => {
  const [isDebugPanelVisible, setIsDebugPanelVisible] = useAtom(debugPanelVisibleAtom)
  const [debugPanelContent, setDebugPanelContent] = useAtom(debugPanelContentAtom)
  const [debugPanelCollapse, setDebugPanelCollapse] = useAtom(debugPanelCollapseAtom)

  const toggleDebugPanel = () => {
    setIsDebugPanelVisible((val) => !val)
  }

  return {
    debugPanelCollapse,
    debugPanelContent,
    isDebugPanelVisible,
    setDebugPanelCollapse,
    setDebugPanelContent,
    setIsDebugPanelVisible,
    toggleDebugPanel,
  }
}
