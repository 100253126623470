import {atom, useAtom} from 'jotai'

const primaryNavExpandedState = atom<boolean>(false)

const primaryNavLockedState = atom<boolean>(false)

export const usePrimaryNav = () => {
  const [isPrimaryNavExpanded, setIsPrimaryNavExpanded] = useAtom(primaryNavExpandedState)
  const [isPrimaryNavLocked, setIsPrimaryNavLocked] = useAtom(primaryNavLockedState)

  return {
    isPrimaryNavLocked,
    setIsPrimaryNavLocked,
    isPrimaryNavExpanded,
    setIsPrimaryNavExpanded,
  }
}
