import {matchPath} from 'react-router'

import {F, T, cond} from '@settleindex/fp'

import type {PrimaryMenuItem} from './PrimaryMenuItem'

export const addIsActiveToMenuItems = (currentPath: string, menuItems: PrimaryMenuItem[]): PrimaryMenuItem[] =>
  menuItems.map((item) => {
    if (item === null) {
      return item
    }

    const match = item.linkPath ? matchPath(currentPath, item.linkPath) : null
    const isActive = cond([
      [() => item?.isActive === false, F],
      [() => !!match?.pathname, T],
    ])()

    return {
      ...item,
      isActive,
    }
  })
